<template>
  <v-dialog
      v-model="dialog"

      max-width="600"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} room feature</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined :error="$v.room_attribute.name.$error"
                  dense
                  v-model="room_attribute.name"
              >
                <template v-slot:label>
                  Feature title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.room_attribute.name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.name" >**{{errors.name[0]}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="currentUser.access_type!='score'">
              <v-select
                  outlined
                  dense item-text="name"
                  item-value="id" :error="$v.room_attribute.score_id.$error"
                  :items="scores" :menu-props="{ bottom: true, offsetY: true }"
                  v-model="room_attribute.score_id"
              >
                <template v-slot:label>
                  SCORE <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.room_attribute.score_id.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              Status
              <v-switch
                  :label="room_attribute.is_active ? 'Active' : 'Inactive'"
                  v-model="room_attribute.is_active"
              ></v-switch>
              <span class="text-danger" v-if="errors.is_active" >**{{errors.is_active[0]}}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          x-large
          text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
           dark
           x-large
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>


      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import {required, numeric, requiredIf} from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";
import RoomAttributeService from "@/services/practical-exam/venue/room-attribute/RoomAttributeService";
const roomAttribute=new RoomAttributeService();
const score = new ScoreService();
export default {
  validations:{
    room_attribute:{
      name:{required},
      score_id:{
        required: requiredIf(function (nestedModel){
          return this.currentUser.access_type!='score';
        }),
      },
    }
  },
  data() {
    return {
      title: '',
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      currentUser:{},
      dialog: false,
      edit: false,
      loading: false,
      errors: [],
      scores:[],
      room_attribute:{
        name:'',
        score_id:'',
        is_active:true,
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$emit('refresh');
    },
    openDialog() {
      this.$v.$reset()
      this.dialog = true;
    },
    createRoomAttribute() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Create";
    },
    editRoomAttribute(item) {
      this.openDialog();
      this.edit = true;
      this.room_attribute = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {

      this.loading = true;
      roomAttribute
          .create(this.room_attribute)
          .then(response => {
            this.$snotify.success("Room feature added");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {

            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      roomAttribute
          .update(this.room_attribute.id, this.room_attribute)
          .then(response => {
            this.$snotify.success("Room feature updated");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          })
    },
    resetForm(){
      this.room_attribute={
        name:'',
        score_id:'',
        is_active:true,
      };
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }

  },
  mounted() {
    this.getCurrentUser();
    this.getAllScore();
  },
  computed:{

  }
}
</script>
