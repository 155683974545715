<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Feature titles</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Feature title
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                  <v-btn  @click="createRoomAttribute()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add room feature
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Room feature info"  v-on:keyup.enter="searchRoomAttribute" v-model="search.info" clearable outlined dense></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12"  md="3" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      :items="scores"  v-on:keyup.enter="searchRoomAttribute"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined :menu-props="{ bottom: true, offsetY: true }"
                      dense clearable>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="status"   v-on:keyup.enter="searchRoomAttribute"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined clearable
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12"  :md="currentUser.access_type == 'score' ? 6 : 3" class="text-right">
                  <v-btn :loading="isLoading"
                      @click="searchRoomAttribute"
                      class="btn btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Feature</th>
                  <th class="px-3">SCORE</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="room_attributes.length > 0" v-for="item in room_attributes">
                    <td class="px-2">

                      <a @click="editRoomAttribute(item)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.name }}
                      </a>
                    </td>
                    <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{item.score_name}}
                        </span>
                    </td>
                    <td>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                      >{{ item.status_text }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editRoomAttribute(item)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit room feature</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="deleteRoomAttribute(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="room_attributes.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  :disabled="isLoading"
                  v-if="room_attributes.length > 0"
                  class="pull-right mt-7"
                  @input="getAllRoomAttribute"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-or-update ref="create-or-update" @refresh="getAllRoomAttribute"></create-or-update>

    </div>
  </v-app>
</template>
<script>
import RoomAttributeService from "@/services/practical-exam/venue/room-attribute/RoomAttributeService";
import ScoreService from "@/services/score/score/ScoreService";
import CreateOrUpdate from './CreateOrUpdate';
const score = new ScoreService();
const roomAttribute=new RoomAttributeService();

export default {
  components:{
    CreateOrUpdate,
  },
  data(){
    return{
      search:{
        info:'',
        is_active:'',
        score_id:'',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      room_attributes:[],
      total: null,
      perPage: null,
      page: null,
      scores:[],
      currentUser:{},
      isLoading:false,
    }
  },
  methods:{
    getAllRoomAttribute(){
      this.isLoading = true;
      roomAttribute
      .paginate(this.search,this.page)
          .then(response => {
            this.room_attributes=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {

          });
    },
    searchRoomAttribute(){
      this.getAllRoomAttribute();
    },
    deleteRoomAttribute(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            roomAttribute
                .delete(item)
                .then((response) => {
                  this.getAllRoomAttribute();
                  this.$snotify.success("Room feature deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    createRoomAttribute(){
      this.$refs['create-or-update'].createRoomAttribute(this.scoreId);
    },
    editRoomAttribute(item) {
      this.$refs['create-or-update'].editRoomAttribute(item);
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getAllRoomAttribute();
    this.getAllScore();
  }
}
</script>